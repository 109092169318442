<template>
  <div v-if="ready">
    <b-row class="mb-3">
      <b-col md="4" class="text-center">
        <b-button @click="prev()" size="sm" variant="primary" class="btn-icon"><i class="fas fa-chevron-left"></i></b-button>
        <span class="ml-5 mr-5 font-weight-bold h4 upper">{{title}}</span>
        <b-button @click="next()" size="sm"  variant="primary" class="btn-icon"><i class="fas fa-chevron-right"></i></b-button>
      </b-col> 
      <b-col md="4">
          <b-form-input placeholder="Rechercher" v-model="searchQ"></b-form-input>
          <b-list-group class="search-result" v-if="searchR.length > 0">      
            <b-list-group-item href="javascript:void(0)" v-for="(r,i) in searchR" :key="i" @click="openEvent(r.id)">{{ r.data.titre }} <small>({{ $api.moment(r.data.date).format("DD/MM/YYYY") }}) à {{ r.data.heure }}</small></b-list-group-item>      
          </b-list-group>        
      </b-col>  
      <b-col md="4" class="text-center">
        <b-dropdown text="Mois" variant="primary" no-caret>
          <b-dropdown-item href="/#/agenda/mois" active>Mois</b-dropdown-item>
          <b-dropdown-item href="/#/agenda/semaine">Semaine</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
          <div id="categorie" class="sticky-top">
            <b-button v-for="(t, i) in $api.params.AgendaCategorie" @click="categorie_id = t.id; initMonth()" :key="i" :variant="`light-${t.color}`" class="mt-2 d-block">{{ t.titre }}</b-button>            
          </div>
      </b-col>
      <b-col md="10">
        <b-row v-for="(w, wi) in month" :key="wi" class="week">
          <b-col v-for="(d , di) in w.week" :key="di" class="day">            
            <p class="title text-dark font-weight-bold  upper mt-2">
              <span v-if="wi === 0">
                {{d.day.format("ddd")}}<br>
              </span>
              <b-button :variant="ifNow(d) ? 'primary' : 'none'" @click="createEventFromDate(d)" size="sm" class="h5 font-weight-bolder">
                {{d.day.format("D")}}
              </b-button>          
            </p>
            <div v-for="(l, li) in d.liste" :key="li" class="event p-1">
              <div @click="openEvent(l.id)" v-b-tooltip.hover :title="l.data.titre">
                <span :class="`text-${$api.getParam('AgendaCategorie', l.data.categorie_id).color}`" class="puce"></span>
                <span>{{l.data.heure}} <strong>{{l.data.titre}}</strong></span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal id="eventAgenda" ref="eventAgenda" hide-footer title="RDV" size="lg" centered>
      <b-row>
        <b-col md="6">
          <b-form-group label="Titre">
            <Input type="text" v-model="eventTmp.data.titre" :required="true" placeholder="titre"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Categorie">
            <Input type="select-model" v-model="eventTmp.data.categorie_id" :required="true" :options="$api.params.AgendaCategorie" :multiple="false"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Date">
            <input type="date" class="form-control" v-model="eventTmp.data.date" :required="true" placeholder="date" :min="$api.moment().format('YYYY-MM-DD')"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Heure">
            <input class="form-control" type="time" v-model="eventTmp.data.heure" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Telephone">
            <Telephone v-model="eventTmp.data.telephone" :edit="true" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Description">
            <Input type="textarea" v-model="eventTmp.data.description" :required="true" placeholder="description"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-button variant="outline-primary" block @click="$refs.eventAgenda.hide()">Fermer</b-button>
        </b-col>
        <b-col md="4" class="text-center">
          <b-button-group>
            <b-button variant="outline-primary" class="btn-icon" :href="eventTmp.data.url" v-b-tooltip.hover title="voir le lien">
              <i class="fas fa-eye"></i>
            </b-button>  
            <b-button variant="danger" class="btn-icon ml-1" @click="deleteEvent(eventTmp.id)" v-b-tooltip.hover title="Supprimer l'evenement">
              <i class="fas fa-trash"></i>
            </b-button>  
          </b-button-group>          
        </b-col>
        <b-col md="4">
          <b-button variant="primary" block @click="updateEvent">Mettre a jour</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import Input from '@/components/InputForm';
import Telephone from '@/components/Telephone';
export default {
  name: "AgendaMois",
  components: {
    Input, Telephone
  },
  data(){
    return {
      type:'month',
      now: null,
      month: [],
      ready: false,
      eventTmp:{
        "id": null,
        "data": {
          "titre": null,
          "date": null,
          "heure": null,
          "description": null,
          "categorie_id": null,
          "url":null,
        }
      },
      categorie_id:null,
      searchQ:null,
      searchR:[]
    }
  },
  watch:{
    'now'(){
      this.initMonth();
    },
    'searchQ'(val){
      if(val === null){
        return false;
      }
      if(val.length < 3){
        return false
      }
      this.search(val);
    }
  },
  computed:{
    title(){
      return this.now.format('MMMM YYYY');
    },
    dateStart(){
      var t = this.month[0].week[0].day;
      return t.unix();
    },
    dateEnd(){
      var last_week = this.month.length - 1;
      var last_day = this.month[last_week].week.length -1;
      var t = this.month[last_week].week[last_day].day;
      return t.unix();
    }
  },
  methods:{
    ifNow(d){
      if(d.date === moment().format("YYYY-MM-DD")){
        return true;
      }else{
        return false;
      }
    },
    prev(){
      this.now = moment(this.now).subtract(1,'months');
    },
    next(){
      this.now = moment(this.now).add(1,'months');
    },
    initMonth(){
      this.month = [];
      const today = this.now;
      const startDay = today.clone().startOf('month').startOf('week');
      const endDay = today.clone().endOf('month').endOf('week');
      let date = startDay.clone().subtract(1, 'day');
      while (date.isBefore(endDay, 'day')){
        var week = Array(7).fill(0).map(() => {
          var t = date.add(1, 'day').clone();
          return {
            day:t,
            date: t.format("YYYY-MM-DD"),
            liste:[]
          }
        });
        this.month.push({
          week:week,
          week_number: week[0].day.isoWeek()
        });
      }            
      var params = {
        "min_data.time": this.dateStart,
        "max_data.time": this.dateEnd,
        "data.categorie_id": this.categorie_id
      }
      this.$api.ajax('/agenda/liste', {filters:params}, res => {
        res.data.forEach((event) => {          
          var week = this.month.find(w => w.week_number === event.week);          
          if(week != undefined){
            var day = week.week.find(d => d.date === event.data.date);
            if(day != undefined){
              day.liste.push(event);
            }
          }
        });

      })
    },
    createEventFromDate(d){
      this.eventTmp = {
        "id": null,
        "data": {
          "titre": null,
          "date": d.date,
          "heure": null,
          "description": null,
          "categorie_id": null,
          "url":null,
        }
      }      
      this.$refs.eventAgenda.show();
    },
    openEvent(id){
      this.$api.ajax('/agenda/'+id, null, res => {
        if(res.status === true){
          this.eventTmp = res.data;
          this.$refs.eventAgenda.show();
        }
      })
    },
    updateEvent(){
      this.$api.ajax('/agenda/create', this.eventTmp, res => {
        if(res.status === true){
          this.$refs.eventAgenda.hide();
          this.initMonth();
        }
      })
    },
    deleteEvent(id){
      this.$api.ajax('/agenda/delete/'+id, null, res => {
        if(res.status === true){
          this.$refs.eventAgenda.hide();
          this.initMonth();
        }
      })
    },
    search(val){
      this.$api.ajax('/agenda/search/'+val, null, res => {
        if(res.status === true){
          this.searchR = res.data;          
        }
      })
    },
  },
  mounted() {
    this.now = moment();
    this.ready = true;
  }
}
</script>
<style lang="scss" scoped>
.week {
  background: white;
  display: -webkit-box;
}
.day {
  height: 200px;
  overflow: scroll;
  border: solid #dadce0 1px;
  // overflow: hidden;
}
.day .title{
  text-align: center
}
.upper {
  text-transform: capitalize;
}
// .month{
//   display: flex;
//   flex-direction: column;
// }
.puce {
  border-radius: 8px;
  border: 4px solid;
  height: 0;
  width: 0;
  color: #3c4043;
  fill: #3c4043;
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: 5px
}
.event {
  overflow: hidden;
  width: 90%;
  white-space: nowrap;
  font-size: 11px;
}
.event:hover {
  background-color: #f1f3f4;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #f1f3f4;
}
#categorie {
  top:15% !important;
}
.search-result {
  position: absolute;
  width: 88%;
  z-index: 1;
}
</style>
